import profilePic1 from "./feedbackProfile1.jpeg";
import profilePic2 from "./feedbackProfile2.jpg";
import profilePic4 from "./feedbackProfile4.png";
import profilePic5 from "./feedbackProfile5.png";
import profilePic6 from "./feedbackProfile6.png";
import profilePic8 from "./feedbackProfile8.jpg";
import profilePic9 from "./feedbackProfile9.png";
import profilePic10 from "./feedbackProfile10.webp";
import profilePic11 from "./feedbackProfile11.webp";
import profilePic12 from "./feedbackProfile12.png";
import profilePic13 from "./feedbackProfile13.png";
import profilePic14 from "./feedbackProfile14.jpeg";
import profilePic15 from "./feedbackProfile15.jpeg";

import femalePlaceholder from "./femalePlaceholder.png";
import malePlaceholder from "./malePlaceholder.png";
import zivovLogo from "./logo.png";
import banner_image_trans from "./check8.webp";
import diabetes_new from "./diabetes_new.webp";
import kidney_new from "./kidney_new.webp";
import bp_new from "./bp_new.webp";
import preDiabetesLogo from "./preDiabetes.webp";
import prevention_new from "./prevention_new.webp";
import condition_new from "./condition_new.webp";
import adherence from "./adherence_trans.webp";
import insights from "./insights_trans.webp";
import goals_img_blue from "./2.png";
import playStore from "./play_store.webp";
import two from "./2.webp";
import three from "./3.webp";
import four from "./4.webp";
import five from "./5.webp";
import six from "./6.webp";
import seven from "./7.webp";
import eight from "./8.webp";
import default_girl from "./default_girl.webp";
import results from "./results.webp";
import appStoreIcon from "./app_store.webp";
import doctor from "./doctor.jpeg";
import diet from "./diet.jpeg";
import fitness from "./fitness.jpeg";
import glucose from "./glucose.png";
import zivovdoctor from "./zivovdoctor.jpeg";
import pngtree from "./pngtree.png";
import mobileMetabolic from "./mobileMetabolic.png";
import femalemetabolicmodel from "./femalemetabolicmodel.png";
import mobileMetabolic2 from "./mobileMetabolic2.png";
import phonegraphBackground from "./phonegraphBackground.png";
import metabolicScore from "./metabolicScore.png";
import metabolicFemaleBanner from "./metabolicFemaleBanner.png";
import metabolicBannerGraph from "./metabolicBannerGraph.png";
import metabolicBannerInfo from "./metabolicBannerInfo.png";
import sensor from "./sensor.png";
import metabolicFoodCompare from "./metabolicFoodCompare.svg";
import bannerpath from "./bannerpath.svg";
import backgroundLines1 from "./backgroundLines1.png";
import backgroundLines2 from "./backgroundLines2.png";
import Screenshot from "./Screenshot.png";
import metabolicDoctor from "./metabolicDoctor.png";
import backgroundCircle from "./backgroundCircle.png";
import plank from "./plank.png";
import plank2 from "./plank2.png";
import sensorinfoLine1 from "./sensorinfoLine1.png";
import infoCircle1 from "./infoCircle1.png";
import infoCircle2 from "./infoCircle2.png";
import sensorinfoLine2 from "./sensorinfoLine2.png";
import sensorinfoFemale from "./sensorinfoFemale.png";
import fitbit from "./fitbit.png";
import googlefit from "./googlefit.png";
import applicator from "./applicator.png";
import homePath1 from "./homePath1.png";
import homePath2 from "./homePath2.png";
import zenModel from "./zenModel.png";
import careModel from "./careModel.png";
import insta from "./insta.png";
import linkedin from "./linkedin.png";
import facebook from "./facebook.png";
import mail from "./mail.png";
import girlOnHighnee from "./Jhulan_HomePage.png";
import ZivovLogo from "./ZivovLogo.png";
import metaBolicHealthScreen from "./metaBolicHealthScreen.png";
import wavesRight from "./curvesRight.png";
import curvesLeft from "./curvesLeft.png";
import LoaderHb1abc from "./LoderhbA1c.png";
import TimeSpentProgress from "./timeSpentProgress.png";
import GlucoseVariablity from "./glucoseVariablity.png";
import girlWithInsights from "./girlWithInsights.png";
import Nudge from "./nudgeScreen.png";
import Notification from "./notificationScreen.png";
import Compare from "./compareMeals.png";
import slidetwo from "./slideTwo.png";
import slidethree from "./slideThree.png";
import slidefour from "./slideFour.png";
import slidefive from "./slideFive.png";
import slidesix from "./slideSix.png";
import unpack from "./firstStep.png";
import load from "./secondStep.png";
import apply from "./thirdStep.png";
import protect from "./fourthStep.png";
import connect from "./finalStep.png";
import GlucoseVisable from "./glucoseVisabality.png";
import GraphScreen from "./Jhulan_metabolic.png";
import NewAmbassador from "./newAmbassador.png";
import TwinLogo from "./twin.png";
import layers from "./layers.png";
import sensorChic from "./sensorModel.png";
import runnigCareGirl from "./runningCare.png";
import doctor_care from "./doctor.png";
import nutrician from "./Coach.png";
import food_icon from "./food.png";
import nfc_scan from "./nfc.png";
import workout from "./workout.png";
import LowSize from "./lowSize.png";
import diagnosis from "./diagnosis.png";
import apple from "./apple.svg";
import playstore1 from "./playstore1.svg";
import landingmodel2 from "./landingmodel2.png";
import landingmodel3 from "./landingmodel3.png";
import landingfood from "./landingfood.svg";
import landingexercise from "./landingexercise.svg";
import landingsleep from "./landingsleep.svg";
import landingstress from "./landingstress.svg";
import landingphone from "./landingphone.svg";
import landinginsights from "./landinginsights.svg";
import landingcompare from "./landingcompare.svg";

const images = {
  PROFILE1: profilePic1,
  PROFILE2: profilePic2,
  PROFILE4: profilePic4,
  PROFILE5: profilePic5,
  PROFILE6: profilePic6,
  PROFILE8: profilePic8,
  PROFILE9: profilePic9,
  PROFILE10: profilePic10,
  PROFILE11: profilePic11,
  PROFILE12: profilePic12,
  PROFILE13: profilePic13,
  PROFILE14: profilePic14,
  PROFILE15: profilePic15,

  // PROFILE3: profilePic3,
  GIRLRUNNING: girlOnHighnee,
  ZivovLogo: ZivovLogo,
  LoaderHb1abc: LoaderHb1abc,
  CurvesLeft: curvesLeft,
  TimeSpentProgress: TimeSpentProgress,
  GlucoseVariablity: GlucoseVariablity,
  Notification: Notification,
  CompareMeals: Compare,
  GlucoseVisable: GlucoseVisable,
  GirlWithInsights: girlWithInsights,
  Nudge: Nudge,
  Slidetwo: slidetwo,
  Slidethree: slidethree,
  Slidefour: slidefour,
  Slidefive: slidefive,
  Slidesix: slidesix,
  Unpack: unpack,
  Load: load,
  Apply: apply,
  Protect: protect,
  Connect: connect,
  GraphScreen: GraphScreen,
  NewAmbassador: NewAmbassador,
  TwinLogo:TwinLogo,
  SensorChic: sensorChic,
  Layers: layers,
  RunnigCareGirl: runnigCareGirl,
  Workout: workout,
  Doctor_care: doctor_care,
  Nutrician: nutrician,
  Food_icon: food_icon,
  Nfc_scan: nfc_scan,
  LowSize: LowSize,
  Diagnosis: diagnosis,
  femalePlaceholder: femalePlaceholder,
  malePlaceholder: malePlaceholder,
  BANNER_IMG: banner_image_trans,
  DIABETES: diabetes_new,
  KIDNEY: kidney_new,
  landingmodel2: landingmodel2,
  landingmodel3: landingmodel3,
  landingfood: landingfood,
  landingexercise: landingexercise,
  landingsleep: landingsleep,
  landingstress: landingstress,
  landingphone: landingphone,
  landinginsights: landinginsights,
  landingcompare: landingcompare,
  BP: bp_new,
  zivovLogo: zivovLogo,
  PRE_DIABETES: preDiabetesLogo,
  CONDITION: condition_new,
  PREVENTION: prevention_new,
  ADHERENCE: adherence,
  INSIGHTS: insights,
  GOALS: goals_img_blue,
  PLAYSTORE: playStore,
  TWO: two,
  THREE: three,
  FOUR: four,
  FIVE: five,
  SIX: six,
  SEVEN: seven,
  EIGHT: eight,
  DEFAULT_GIRL: default_girl,
  RESULTS: results,
  APPSTORE: appStoreIcon,
  ZIVOVDOCTOR: zivovdoctor,
  FITNESS: fitness,
  GLUCOSE: glucose,
  DIET: diet,
  DOCTOR: doctor,
  pngtree: pngtree,
  mobileMetabolic: mobileMetabolic,
  femalemetabolicmodel: femalemetabolicmodel,
  GlucoseScreen: mobileMetabolic2,
  metaBolicHealthScreen: metaBolicHealthScreen,
  wavesRight: wavesRight,
  phonegraphBackground: phonegraphBackground,
  metabolicScore: metabolicScore,
  metabolicFemaleBanner: metabolicFemaleBanner,
  metabolicBannerGraph: metabolicBannerGraph,
  metabolicBannerInfo: metabolicBannerInfo,
  sensor: sensor,
  metabolicFoodCompare: metabolicFoodCompare,
  bannerpath: bannerpath,
  backgroundLines1: backgroundLines1,
  backgroundLines2: backgroundLines2,
  Screenshot: Screenshot,
  metabolicDoctor: metabolicDoctor,
  backgroundCircle: backgroundCircle,
  plank: plank,
  plank2: plank2,
  sensorinfoLine1: sensorinfoLine1,
  infoCircle1: infoCircle1,
  sensorinfoLine2: sensorinfoLine2,
  infoCircle2: infoCircle2,
  sensorinfoFemale: sensorinfoFemale,
  fitbit: fitbit,
  googlefit: googlefit,
  applicator: applicator,
  homePath1: homePath1,
  homePath2: homePath2,
  zenModel: zenModel,
  careModel: careModel,
  insta: insta,
  facebook: facebook,
  mail: mail,
  linkedin: linkedin,
  apple: apple,
  playstore1: playstore1,
};

export default images;
