import React, { useEffect, useState } from "react";
import "./Styles.scss";
import images from "../../Assets/Images";

const SensorApply = (props) => {
  const [Unpack, setUnpack] = useState(true);
  const [Load, setLoad] = useState(false);
  const [Apply, setApply] = useState(false);
  const [Protect, setProtect] = useState(false);
  const [Connect, setConnect] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlechange = (val) => {
    if (val === "Unpack") {
      setUnpack(!Unpack);
      setLoad(false);
      setApply(false);
      setConnect(false);
      setProtect(false);
    } else if (val === "Load") {
      setLoad(!Load);
      setUnpack(false);
      setApply(false);
      setConnect(false);
      setProtect(false);
    } else if (val === "Apply") {
      setApply(!Apply);
      setLoad(false);
      setUnpack(false);
      setConnect(false);
      setProtect(false);
    } else if (val === "Protect") {
      setProtect(!Protect);
      setApply(false);
      setLoad(false);
      setUnpack(false);
      setConnect(false);
    } else {
      setConnect(!Connect);
      setProtect(false);
      setApply(false);
      setLoad(false);
      setUnpack(false);
    }
  };

  return (
    <div className="selectorContainer">
      <div onClick={() => handlechange("Unpack")} className="selector">
        <p
          className="selector_heading"
          style={{ color: Unpack ? "#ffffff" : "#a3a2a2" }}
        >
          UNPACK THE APPLICATOR
        </p>
        <p
          className="selector_description"
          style={{ color: Unpack ? "#ffffff" : "#a3a2a2" }}
        >
          Open The Box To Find Two Items. Peel The Lid Off The Biosensor Pack
          And Unscrew
        </p>
      </div>
      {Unpack && <img src={images.Unpack} className="applypicture" alt="Apply Sensor"/>}
      <div onClick={() => handlechange("Load")} className="selector">
        <p
          className="selector_heading"
          style={{ color: Load ? "#ffffff" : "#a3a2a2" }}
        >
          LOAD THE APPLICATOR
        </p>
        <p
          className="selector_description"
          style={{ color: Load ? "#ffffff" : "#a3a2a2" }}
        >
          Line Up The Biosensor Applicator And Biosensor Pack. Press Down Firmly
          On The Biosensor Applicator
        </p>
      </div>
      {Load && <img src={images.Load} className="applypicture" alt="zivov zen apply sensor"/>}

      <div onClick={() => handlechange("Apply")} className="selector">
        <p
          className="selector_heading"
          style={{ color: Apply ? "#ffffff" : "#a3a2a2" }}
        >
          PAINLESSLY APPLY THE BIOSENSOR
        </p>
        <p
          className="selector_description"
          style={{ color: Apply ? "#ffffff" : "#a3a2a2" }}
        >
          Hold Your Arm Up And Place The Biosensor Applicator Over The Back Of
          Your Upper Arm.
        </p>
      </div>
      {Apply && <img src={images.Apply} className="applypicture" alt="Zivov Zen Apply Sensor"/>}

      <div onClick={() => handlechange("Protect")} className="selector">
        <p
          className="selector_heading"
          style={{ color: Protect ? "#ffffff" : "#a3a2a2" }}
        >
          PROTECT THE BIOSENSOR WITH THE PERFORMANCE PATCH
        </p>
        <p
          className="selector_description"
          style={{ color: Protect ? "#ffffff" : "#a3a2a2" }}
        >
          Secure A New Performance Patch Over The Biosensor To Protect It From
          Knock Off.
        </p>
      </div>
      {Protect && <img src={images.Protect} className="applypicture" alt="Zivov Zen Apply Sensor"/>}

      <div onClick={() => handlechange("Connect")} className="selector">
        <p
          className="selector_heading"
          style={{ color: Connect ? "#ffffff" : "#a3a2a2" }}
        >
          CONNECT
        </p>
        <p
          className="selector_description"
          style={{ color: Connect ? "#ffffff" : "#a3a2a2" }}
        >
          Open Your Zivov App And Follow The Directions To Pair Your New
          Biosensor.
        </p>
      </div>
      {Connect && <img src={images.Connect} className="applypicture" alt="Zivov Zen Apply Sensor"/>}
    </div>
  );
};

export default SensorApply;
