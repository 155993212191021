import React, { memo, useState } from "react"; 
import "./Style.scss";
import imagesFolder from "../../Assets/Images";

import { BsDroplet } from "react-icons/bs";
import { FaWeight, FaArrowRight } from "react-icons/fa"; 
export default memo(
  ({
    isVideo,
    image,
    name,
    age,
    place,
    weight_details,
    weight,
    _before,
    weight_before,
    weight_after,
    HbA1c_before,
    HbA1c_after,
    medicine_details,
    note,
  }) => {
    const [readMore, setReadMore] = useState(0); 

    return (
      <div className="feedBackContainer">
        <div className="feedBackProfile">
          <div>
            <img
              src={`${imagesFolder[image]}`}
              alt="profile pic"
              width="100rem"
              height="100rem"
              className="profileImg"
            />
          </div>
          <div className="profileInfo">
            <div style={{ paddingBottom: "0.5rem", textAlign: "center" }}>
              {name}
            </div>
            <div>
              {age} {place}
            </div>
          </div>
        </div>

        <div className="feedBackInfo">
          <div className="feedBackInfoHeading">
            <table className="table">
              <thead>
                <tr>
                  <th> </th>
                  <th> </th>
                  <th> Before </th>
                  <th> </th>
                  <th>After </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <BsDroplet />
                  </td>
                  <td>HbA1C</td>
                  <td>{HbA1c_before}</td>
                  <td>
                    <FaArrowRight />
                  </td>
                  <td>{HbA1c_after}</td>
                </tr>
                <tr>
                  <td>
                    <FaWeight />
                  </td>
                  <td>Weight</td>
                  <td>{weight_before}</td>
                  <td>
                    <FaArrowRight />{" "}
                  </td>
                  <td>{weight_after}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="line" />
          <div className="feedBackDescription">{medicine_details}</div>
          <div>
            <div className="feedBackDescription">
              {/* {note} */}
              {readMore ? note : `${note.substring(0, 150)}...`}
            </div>

            <p className="readMoreText">
              <span
                onClick={() => setReadMore(!readMore)}
                // onClick={() => {
                //   setReadMore((preValue) => {
                //     let newValue = 0;
                //     if (preValue === 0) {
                //       newValue = 1;
                //     }
                //     return newValue;
                //   });
                // }}
              >
                {!readMore ? "Read More..." : "Read Less"}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
);
