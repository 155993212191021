import React, { PureComponent } from "react";
import { Row, Col } from "react-bootstrap";
import "./Style.scss";
import { withRouter, Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";
import images from "../../../Assets/Images";

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      dialogVisibility: false,
    };
  }

  validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  scrollToSection(id) {
    if (window.location.pathname !== "/patient") {
      this.props.history.push({
        pathname: "/patient",
        state: {},
      });
    }
    var elmnt = document.getElementById(id);
    if (elmnt) {
      elmnt.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  setStateMedthod = (key, value) => {
    this.setState({ [key]: value });
  };

  onPressEnter = (e) => {
    if (e.key === "Enter" && this.validateEmail(this.state.email)) {
      this.sendSubscription();
    }
  };

  sendSubscription = () => {
    if (!this.validateEmail(this.state.email)) {
      this.setStateMedthod("dialogVisibility", !this.state.dialogVisibility);
      return;
    }
  };

  mainTO() {
    window.location.href = "mailto:info@zivov.com";
  }

  setDialogVisibility = () => {
    this.setState({ dialogVisibility: !this.state.dialogVisibility });
  };

  render() {
    return (
      <div>
        <div className="socials">
          <div>
            <a
              href="https://www.instagram.com/zivovhealth/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ProgressiveImage src={images.insta}>
                {(src) => (
                  <img src={src} alt="insta" height="50rem" className="insta" />
                )}
              </ProgressiveImage>
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/zivov"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ProgressiveImage src={images.linkedin}>
                {(src) => (
                  <img
                    src={src}
                    alt="linkedin"
                    height="50rem"
                    className="linkedin"
                  />
                )}
              </ProgressiveImage>
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/zivovhealth"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ProgressiveImage src={images.facebook}>
                {(src) => (
                  <img
                    src={src}
                    alt="facebook"
                    height="50rem"
                    className="facebook"
                  />
                )}
              </ProgressiveImage>
            </a>
          </div>
          <div>
            {" "}
            <ProgressiveImage src={images.mail}>
              {(src) => (
                <img
                  src={src}
                  alt="mail"
                  height="50rem"
                  className="mail"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.mainTO();
                  }}
                />
              )}
            </ProgressiveImage>
          </div>
        </div>
        <div className="footerContainer">
          <Row className="footerContainer_row">
            <Col md={4}>
              <Link to="/termscondition">
                <p className="f2">Terms and Conditions</p>
              </Link>
            </Col>
            <Col md={3}>
              <Link to="/privacypolicy">
                <p className="f2">Privacy Policy</p>
              </Link>
            </Col>
            <Col md={3}>
              <a href="tel:+919711932973">
                <p className="f2">Contact@ +91 9711932973</p>
              </a>
            </Col>
          </Row>
        </div>
        <div
          className="download"
          style={{ paddingBottom: "3rem", paddingTop: "2rem" }}
        >
          Get Zivov on{" "}
          <span style={{ padding: "0.5rem" }}>
            <a
              href={process.env.REACT_APP_STORE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ProgressiveImage src={images.apple}>
                {(src) => <img src={src} alt="apple" className="apple" />}
              </ProgressiveImage>
            </a>
          </span>
          <span style={{ padding: "1rem" }}>
            <a
              href={process.env.REACT_APP_PLAYSTORE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ProgressiveImage src={images.playstore1}>
                {(src) => (
                  <img src={src} alt="playstore1" className="playstore1" />
                )}
              </ProgressiveImage>
            </a>
          </span>
          <span>
            <p className="copyRight_text">
                Copyright@2023 Zivov Technologies Pvt. Ltd. All rights reserved.
            </p>
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
