import React, { useEffect } from "react";
import "./Style.scss";
import ProgressiveImage from "react-progressive-graceful-image";
import images from "../../Assets/Images";
import { Link } from "react-router-dom";
import Footer from "../../Components/Layout/Footer"; 
import { Col } from "react-bootstrap";

//homePath1 homePath2  zenModel careModel
const Home = () => {

  const arrow = ">";
  const newTo = {
    pathname: "/metabolic",
    param1: "buyNow",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home">
      <div className="hero">
        <div className="heroleft">
          <div className="ZivovLogo_container">
            <ProgressiveImage src={images.ZivovLogo}>
              {(src) => (
                <img src={src} alt="bannerpath" className="ZivovLogo" />
              )}
            </ProgressiveImage>
            <p className="zivov"> Zivov</p>
          </div>
          <div className="textPlain">It’s time to</div>
          <div className="text">Join</div>
          <div className="text_the">The</div>
          <div className="text_rev">Revolution</div>
          <Link to={"/metabolic"}>
            <div className="texthead">
              <div className="buttongradient">Unlock your Metabolic health</div>
            </div>
          </Link>
        </div>
        <div className="heroright">
          <div className="mobile_container"></div>
          <ProgressiveImage src={images.GIRLRUNNING}>
            {(src) => <img src={src} alt="insta" className="graphImage_Screen" />}
          </ProgressiveImage>
          <div className="Jhulan_Criket">
          <div className="Jhulan_name"> Jhulan Goswami</div>
          <div className="Jhulan_name">Indian Cricket Legend</div>
          </div>
          {/* <ProgressiveImage src={images.GraphScreen}>
            {(src) => <img src={src} alt="insta" className="graphImage" />}
          </ProgressiveImage> */}
          
        </div>
      </div>

      <div className="zen_container center ">
        <centre>
          <h3 className="headingZen">Zivov Zen</h3>
        </centre>
        <div className="groupItems">
          <Col lg={4}>
            <ProgressiveImage src={images.Layers}>
              {(src) => <img src={src} alt="layers" className="mobileLayers" />}
            </ProgressiveImage>
          </Col>
          <Col lg={4}>
            <centre className="Zen_info">
              <p className="Zen_info_context">
                Optimize your diet and fitness using personalized insights from
                the Zen sensor
              </p>
              <Link to={newTo}>
                <button className="btn-grad">Buy Now</button>
              </Link>

              <Link to="/metabolic" relative="path">
                <button className="btn-learn">
                  Learn More <span>{arrow}</span>
                </button>
              </Link>
            </centre>
          </Col>
          <Col lg={4}>
            <ProgressiveImage src={images.SensorChic}>
              {(src) => <img src={src} alt="chic" className="girlInWhite" />}
            </ProgressiveImage>
          </Col>
        </div>
      </div>
      <div className="care_container">
        <h3 className="headingZen">Zivov Care</h3>
        <div className="groupItems">
          <Col lg={4}>
            <ProgressiveImage src={images.RunnigCareGirl}>
              {(src) => <img src={src} alt="layers" className="mobileLayers" />}
            </ProgressiveImage>
          </Col>
          <Col lg={4}>
            <centre className="Zen_info">
              <p className="Zen_info_context">
                India’s most comprehensive AI-based preventive & chronic care
                platform
              </p>
              <Link
                style={{ textDecoration: "none" }}
                to="/zivovcare"
                relative="path"
              >
                <button className="btn-grad">Buy Now</button>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/zivovcare">
                <button className="btn-learn">
                  Learn More <span>{arrow}</span>
                </button>
              </Link>
            </centre>
          </Col>
          <Col lg={4}>
            <div className="icons_grp">
              <div>
                <div>
                  <div className="care_icons">
                    <ProgressiveImage src={images.Nfc_scan}>
                      {(src) => (
                        <img src={src} alt="layers" className="icon_set" />
                      )}
                    </ProgressiveImage>
                  </div>
                  <p className="iconText">CGM Sensor</p>
                </div>
                <div>
                  <div className="care_icons">
                    <ProgressiveImage src={images.Doctor_care}>
                      {(src) => (
                        <img src={src} alt="layers" className="icon_set" />
                      )}
                    </ProgressiveImage>
                  </div>
                  <p className="iconText">Doctor</p>
                </div>
              </div>
              <div>
                <div>
                  <div className="care_icons">
                    <ProgressiveImage src={images.Food_icon}>
                      {(src) => (
                        <img src={src} alt="layers" className="icon_set" />
                      )}
                    </ProgressiveImage>
                  </div>
                  <p className="iconText">Diet</p>
                </div>

                <div>
                  <div className="care_icons">
                    <ProgressiveImage src={images.Diagnosis}>
                      {(src) => (
                        <img src={src} alt="layers" className="icon_set" />
                      )}
                    </ProgressiveImage>
                  </div>
                  <p className="iconText">Diagnostics</p>
                </div>
              </div>
              <div>
                <div>
                  <div className="care_icons">
                    <ProgressiveImage src={images.Workout}>
                      {(src) => (
                        <img src={src} alt="layers" className="icon_set" />
                      )}
                    </ProgressiveImage>
                  </div>
                  <p className="iconText">Fitness</p>
                </div>
                <div>
                  <div className="care_icons">
                    <ProgressiveImage src={images.Nutrician}>
                      {(src) => (
                        <img src={src} alt="layers" className="icon_set" />
                      )}
                    </ProgressiveImage>
                  </div>
                  <p className="iconText">Coach</p>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>

      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
