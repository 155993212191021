import React, { memo } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PageSpinner from "../Loader";
import { MainLayout } from "../Layout";
import Constant from "../../Providers/ConstantProvider";
import Home from "../../Pages/Home";
import ZivovCare from "../../Pages/ZivovCare";
import SensorApply from "../../Pages/KnowMore";
const TestimonialPage = React.lazy(() => import("../../Pages/Testimonials"));
const MetabolicPage = React.lazy(() => import("../../Pages/Metabolic"));
const MetabolicLanding = React.lazy(() =>
  import("../../Pages/MetabolicLanding")
);
const SensorInfoPage = React.lazy(() => import("../../Pages/SensorInfo"));
//const ReferPage = React.lazy(() => import('../../Pages/Refer/'));
const ReferDetailPage = React.lazy(() => import("../../Pages/Refer_Detail/"));
const Blog = React.lazy(() => import("../../Pages/Blogs/"));
const BlogDetail = React.lazy(() => import("../../Pages/Blogs/Blog_Detail"));
const TermsAndConditlion = React.lazy(() =>
  import("../../Pages/TermsAndCondition")
);
const Privacy = React.lazy(() => import("../../Pages/Privacy"));
const PlanCatogry = React.lazy(() => import("../../Pages/PlanCategory"));
const Plans = React.lazy(() => import("../../Pages/PlanCategory/Plans"));
const LandingPage = React.lazy(() => import("../../Pages/LandingPage"));
const DownloadReport = React.lazy(() => import("../../Pages/DownloadReportPage"));
const LoginProfile = React.lazy(() => import("../../Pages/LoginProfile"));


const App = memo((props) => {
  let regex = new RegExp("[\\?&]invite_code=([^&#]*)");
  let results = regex.exec(window.location.href);
  Constant.invitationCode =
    results === null ? "" : decodeURIComponent(results[1]);
  return (
    <React.Suspense fallback={<PageSpinner />}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Home
              path="/home"
              component={Home}
              pageHeaderTitle={"mainPage"}
              {...props}
            />
          </Route>
          <Home path="/home" component={Home} {...props} />
          {/* <MainLayout
            path="/home"
            isBackgroundVideoRequired={true}
            component={Home}
            pageHeaderTitle={"mainPage"}
            {...props}
          /> */}
          <MainLayout
            path="/zivovcare"
            isBackgroundVideoRequired={true}
            component={ZivovCare}
            pageHeaderTitle={"zivovCare"}
            {...props}
          />

          <MainLayout
            path="/testimonials"
            component={TestimonialPage}
            pageHeaderTitle={"testimonialPage"}
            {...props}
          />
          <MainLayout
            path="/referdetail"
            component={ReferDetailPage}
            pageHeaderTitle={"referDetailPage"}
            {...props}
          />
          <MainLayout
            path="/blog"
            component={Blog}
            pageHeaderTitle={"blogPage"}
            {...props}
          />
          <MainLayout
            path="/blogdetail/:blog"
            component={BlogDetail}
            pageHeaderTitle={"blogDetailPage"}
            {...props}
          />
          <MainLayout
            path="/blogdetails/:blogTitle/:blog"
            component={BlogDetail}
            pageHeaderTitle={"blogDetailPage"}
            {...props}
          />
          <MainLayout
            path="/planCategory"
            component={PlanCatogry}
            pageHeaderTitle={"PlansCategoryPage"}
            {...props}
          />
          <MainLayout
            path="/plans/:category"
            component={Plans}
            pageHeaderTitle={"PlansPage"}
            {...props}
          />
          <MainLayout
            path="/docPlans/:category/:docplans"
            component={Plans}
            pageHeaderTitle={"DoctorPlans"}
            {...props}
          />
          <MainLayout
            path="/termscondition"
            component={TermsAndConditlion}
            pageHeaderTitle={"termspage"}
            {...props}
          />
          <MainLayout
            path="/privacypolicy"
            component={Privacy}
            pageHeaderTitle={"Privacy"}
            {...props}
          />
          <MainLayout
            path="/metabolic"
            component={MetabolicPage}
            pageHeaderTitle={"Metabolic"}
            {...props}
          />
          <MainLayout
            path="/sensorinfo"
            component={SensorInfoPage}
            pageHeaderTitle={"SensorInfoPage"}
            {...props}
          />
          {/* <MainLayout
            path="/welcome"
            component={LandingPage}
            pageHeaderTitle={"LandingPage"}
            {...props}
          /> */}
          <LandingPage path="/welcome" component={LandingPage} {...props} />
          <MetabolicLanding
            path="/welcomeToZivovZen"
            component={MetabolicLanding}
            {...props}
          />
          <MainLayout path="/SensorApply" component={SensorApply} {...props} />

          <MainLayout
            path="/zest"
            component={DownloadReport}
            pageHeaderTitle={"GlucoseReport"}
            {...props}
          />
          <MainLayout
            path="/deleteAccount"
            component={LoginProfile}
            pageHeaderTitle={""}
            {...props}
          />

          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  );
});

export default App;
