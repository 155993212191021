import React from 'react';
import Router from './Components/Router'; 
import './Theme/index.scss'; 

function App(props) {
  return (
    <Router {...props} />
  );
}

export default App;


