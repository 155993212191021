import React, { PureComponent } from "react";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import "./Style.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { initAPICall } from "../../Providers/ServiceProvider";
import {
  Linkedin,
  Facebook,
  Instagram,
  EnvelopeFill,
} from "react-bootstrap-icons";
import { validatePhoneNumber } from "../../Providers/UtilsProvider";
import ProgressiveImage from "react-progressive-graceful-image";
import images from "../../Assets/Images";
import blood_glucose from "../../Assets/Images/blood_glucose.mp4";

import blood_pressure from "../../Assets/Images/blood_pressure.mp4";
import structured_diet from "../../Assets/Images/check3.MOV";
import zivov_main from "../../Assets/Images/zivov_overall_website.mp4";
import UserFeedback from "../../Components/UserFeedback";
import UserFeedbackJSON from "../../Assets/UserFeedback.json";

class ZivovCare extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fName: "",
      lName: "",
      phoneNumber: "",
      email: "",
      disease: "",
      referDialog: false,
      validated: false,
      errorDialog: false,
      errorMsg: "",
      successDialog: false,
      cardHover: false,
      readMore: [false, false, false],
      change: 1,
      domCreated: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    setTimeout(() => this.setState({ domCreated: true }), 5000);
  };

  formClear = () => {
    this.setState({
      fName: "",
      lName: "",
      phoneNumber: "",
      email: "",
      disease: "",
      referDialog: false,
      validated: false,
      errorDialog: false,
      errorMsg: "",
    });
  };

  isCaptchaValidated = false;

  enterPhoneNumber = (phNumberEntered) => {
    let newNumber = validatePhoneNumber(phNumberEntered);
    this.setStatesValue("phoneNumber", newNumber);
  };

  profilePicDisplay = (loc) => {
    return (
      <>
        <div className="profilePicOuterCircle">
          <div className="profilePicInnerCircle">
            <ProgressiveImage src={loc}>
              {(src) => (
                <img src={loc} alt="profile pic" width="100%" height="100%" />
              )}
            </ProgressiveImage>
          </div>
        </div>
      </>
    );
  };

  softVideoPlayer = (url, custom_width = "100%") => {
    if (this.state.domCreated) {
      return (
        <video
          autoPlay={true}
          playsInline
          loop={true}
          muted={true}
          style={{ width: custom_width }}
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <div
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner
            animation="border"
            variant="primary"
            style={{ fontSize: "1.8rem" }}
          />
        </div>
      );
    }
  };

  raise = (e, unit) => {
    e.target.style.transform = `translate(0px,${unit}px)`;
    e.target.style.transition = "0.3s";
  };

  goalLogo = (logo, logoText) => {
    return (
      <Col
        xs={1}
        className="goalLogoCol"
        onMouseOver={(e) => {
          this.raise(e, -5);
        }}
        onMouseLeave={(e) => {
          this.raise(e, 5);
        }}
      >
        <ProgressiveImage src={logo}>
          {(src) => (
            <img
              alt={logoText}
              src={src}
              height="75vmin"
              width="75vmin"
              className="goalLogoImage"
            />
          )}
        </ProgressiveImage>
        <br />
        <span className="texts">{logoText}</span>
      </Col>
    );
  };

  userFeedBack = (
    image,
    name,
    weight_details,
    medicine_details,
    note,
    index,
    type
  ) => {
    let { readMore } = this.state;
    return type === "image" ? (
      <Col lg={4} className="feedBackTile">
        <Row>
          <Col xs={4} />
          <Col xs={4} style={{ textAlign: "center" }}>
            <div>{this.profilePicDisplay(image)}</div>
          </Col>
          <Col xs={4} />
        </Row>
        <Row className="headline1Container">
          <Col>
            <p className="headline1Style">
              <i>{name}</i>
            </p>
          </Col>
        </Row>
        <Row className="headline2Container">
          <Col>
            <p className="headline2Style">
              {weight_details}
              <br />
              {medicine_details}
            </p>
          </Col>
        </Row>
        <Row
          className="noteText"
          style={!this.state.readMore[index] ? { height: "100px" } : {}}
        >
          <Col>
            <p>{note}</p>
          </Col>
        </Row>
        <p className="readMoreText">
          <a
            onClick={() => {
              readMore.splice(index, 1, !readMore[index]);
              this.setState({
                readMore: readMore,
                change: this.state.change - 1,
              });
            }}
          >
            {!this.state.readMore[index] ? "Read More..." : "Read Less"}
          </a>
        </p>
      </Col>
    ) : (
      <Col lg={4} className="feedBackTile">
        <Row>
          <Col xs={1} />
          <Col xs={10} style={{ textAlign: "center" }}>
            <iframe width="100%" height="315" src={image} title="Zivov Zen Feedback"></iframe>
          </Col>
          <Col xs={1} />
        </Row>
      </Col>
    );
  };

  registerUsertype = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { fName, lName, phoneNumber, email, disease } = this.state;

    if (fName.length < 1) {
      this.setState(
        { errorMsg: { msg: "Please enter your first name", title: "Error" } },
        () => {
          this.setStatesValue("errorDialog", !this.state.errorDialog);
        }
      );

      return;
    }
    if (lName.length < 1) {
      this.setState(
        { errorMsg: { msg: "Please enter your last name", title: "Error" } },
        () => {
          this.setStatesValue("errorDialog", !this.state.errorDialog);
        }
      );
      return;
    }

    if (phoneNumber.length < 10) {
      this.setState(
        {
          errorMsg: {
            msg: "Please enter a valid Phone number",
            title: "Error",
          },
        },
        () => {
          this.setStatesValue("errorDialog", !this.state.errorDialog);
        }
      );
      return;
    }

    console.log(this.validateEmail(email) ? "true" : false);
    if (!this.validateEmail(email)) {
      this.setState(
        {
          errorMsg: {
            msg: "Please enter a valid Email Adress",
            title: "Error",
          },
        },
        () => {
          this.setStatesValue("errorDialog", !this.state.errorDialog);
        }
      );
      return;
    }

    if (!this.isCaptchaValidated) {
      this.setState(
        { errorMsg: { msg: "Please validate captcha", title: "Error" } },
        () => {
          this.setStatesValue("errorDialog", !this.state.errorDialog);
        }
      );
      return;
    }

    this.setState({ validated: true });

    let configObj = {
      method: "post",
      path: "unAuthenticatedRoutes/contactUsWebSite",
      params: {
        first_name: fName.trim().toLowerCase(),
        last_name: lName.trim().toLowerCase(),
        phoneNumber: phoneNumber,
        disease: disease,
        emailID: email,
        message: "webUser",
      },
    };
    initAPICall(configObj).then(
      (response) => {
        if (response.data.error) {
          console.log("eeeeeeeeeeror");
          this.setState(
            { errorMsg: { msg: response.data.error, title: "Error" } },
            () => {
              this.setStatesValue("errorDialog", !this.state.errorDialog);
            }
          );
        }
        if (response.data.isSuccessful) {
          console.log("success");
          this.setState(
            { successDialog: true, errorMsg: { msg: "", title: "Error" } },
            () => {
              this.setStatesValue("errorDialog", !this.state.errorDialog);
            }
          );

          setTimeout(() => {
            this.setState({ successDialog: false });
          }, 2000);
        }
      },
      (error) => {
        console.log("eeeeeeeeeeror2");
        this.setState(
          {
            errorMsg: {
              msg: "Server is not responding. Please try after some time.",
              title: "Error",
            },
          },
          () => {
            this.setStatesValue("errorDialog", !this.state.errorDialog);
          }
        );
      }
    );
  };

  setStatesValue = (key, Value) => {
    this.setState({ [key]: Value });
  };

  enterEmail = (key, value) => {
    this.setState({ [key]: value });
  };

  validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  mainTO() {
    window.location.href = "mailto:contact@zivov.com";
  }

  setDialogVisibility = () => {
    this.setState({ dialogVisibility: !this.state.dialogVisibility });
  };

  render() {
    const { domCreated } = this.state;
    return (
      <div className="parentPage">
        {/* <div className="">hello</div> */}
        <div id="homeSection" className="homeText">
          <Row>
            <Col
              xs={{ span: 12, order: 2 }}
              lg={{ span: 6, order: 1 }}
              className="textSection"
            >
              <p className="upperTextFont">
                ZIVOV
                <br /> CARE
                {/* <br /> made
                <br /> human{"      "} */}
              </p>

              <p className="lowerText">
                India’s most comprehensive AI-based
                <br /> preventive & chronic care platform
              </p>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ span: 5, order: 3 }}
              className="bannerImage"
            >
              <ProgressiveImage src={images.BANNER_IMG}>
                {(src) => <img src={src} width="100%" height="100%" alt={'zivov Zen Banner'}/>}
              </ProgressiveImage>
            </Col>
          </Row>
        </div>

        <div id="aboutSection" className="videoContainer">
          <Row>
            <div style={{ position: "relative", width: "100%" }}>
              {domCreated && this.softVideoPlayer(zivov_main)}
              {!domCreated && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                    style={{ fontSize: "1.8rem" }}
                  />
                </div>
              )}
            </div>
          </Row>
        </div>

        {/* <Row>
          <Col xs={12}>
            <p className="strangeText">
              Take the first step for your better health
            </p>
          </Col>
        </Row> */}

        <div className="healthGoals" id="heatlthGoalsSection">
          <Row>
            <Col xs={0} md={0} lg={0} />
            <Col xs={12} md={12} lg={12} className="heading">
              One platform, Multiple conditions
            </Col>
            <Col xs={12} md={12} lg={12} className="text1">
              Fighting chronic illness alone is tough.
            </Col>
            <Col xs={12} md={12} lg={12} className="text2">
              That's why Zivov works with your doctor along with our team of
              multidisciplinary experts for you to stay healthier
            </Col>
            <Col xs={0} md={1} lg={2} />
          </Row>

          <Row>
            <Col xs={0} md={12}>
              <Row className="goalLogoContainer">
                {this.goalLogo(images.DIABETES, "Diabetes")}
                {this.goalLogo(images.BP, "High Blood Pressure")}
                {this.goalLogo(images.KIDNEY, "Chronic Kidney Disease")}
                {this.goalLogo(images.PRE_DIABETES, "Pre-Diabetes")}
                {this.goalLogo(images.CONDITION, "Prevention")}
                {this.goalLogo(images.PREVENTION, "Weight Management")}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="heading4">
              Zivov is a digital health program that uses the combination of
              behaviour science and unmatched personalized care to help improve
              habits and empower you to live a healthy, disease free life.
            </Col>
          </Row>
        </div>

        {/* <div style={{ height: "400px" }}></div> */}

        <div id="TheAppSection" className="theAppSection_blue_bk">
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <Row>
                <p className="heading">
                  Track your vitals and get actionable insights
                </p>
              </Row>

              <Row>
                <Col md={1}></Col>
                <Col md={5}>
                  <p className="SubHeading">Blood Pressure</p>
                  <br />
                  {domCreated && this.softVideoPlayer(blood_pressure)}
                </Col>

                <Col md={5}>
                  <Col md={1}></Col>
                  <p className="SubHeading">Blood Glucose</p>
                  <br />
                  {domCreated && this.softVideoPlayer(blood_glucose)}
                </Col>
              </Row>

              <Row className="sideTextRow">
                <Col md={1} />
                <Col md={5} className="sidetext">
                  Improve your health through structured diet program with food
                  analysis.
                </Col>

                <Col md={5} style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    {domCreated && this.softVideoPlayer(structured_diet)}
                  </div>
                </Col>
                <hr />
              </Row>

              <Row className="sideTextRow">
                <Col xs={0} md={{ span: 1, order: 1 }} />
                <Col
                  xs={{ span: 12, order: 2 }}
                  md={{ span: 5, order: 2 }}
                  style={{ display: "inline-block", textAlign: "center" }}
                >
                  <div>
                    <div>
                      <ProgressiveImage src={images.ADHERENCE}>
                        {(src) => (
                          <img
                            src={src}
                            width="60%"
                            height="80%"
                            alt="adherence"
                          />
                        )}
                      </ProgressiveImage>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 5, order: 4 }}
                  className="sidetext"
                >
                  Make daily progess by enhancing your adherence
                </Col>
              </Row>
              <Row className="sideTextRow">
                <Col md={1} />
                <Col md={5} className="sidetext">
                  Get health insights and nudges to motivate you to meet your
                  health goals
                </Col>
                <Col
                  md={5}
                  style={{ display: "inline-block", textAlign: "center" }}
                >
                  <div>
                    <div>
                      <ProgressiveImage src={images.INSIGHTS}>
                        {(src) => (
                          <img
                            src={src}
                            width="60%"
                            height="60%"
                            alt="Insights"
                          />
                        )}
                      </ProgressiveImage>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="sideTextRow">
                <Col xs={0} md={{ span: 1, order: 1 }} />
                <Col
                  xs={{ span: 12, order: 2 }}
                  md={{ span: 5, order: 2 }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                    <ProgressiveImage src={images.GOALS}>
                      {(src) => (
                        <img
                          src={src}
                          width="100%"
                          height="100%"
                          alt="Goals"
                          className="goalImage"
                        />
                      )}
                    </ProgressiveImage>
                  </div>
                </Col>
                <Col
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 5, order: 4 }}
                  className="sidetext"
                >
                  Accomplish daily Fitness & lifestyle modification challenges
                </Col>
              </Row>
            </Col>
            <Col md={1}></Col>
          </Row>
        </div>

        {/* <div style={{ height: "400px" }}></div> */}
        {/* 
        <div id="personalisationSection" className="personalizations">
          <Row>
            <Col xs={0} md={2} lg={3} />
            <Col xs={12} md={8} lg={6} className="heading3">
              Unmatched Personalization
              <div style={{ marginTop: "4vh", position: "relative" }}>
                <video
                  autoPlay={true}
                  playsInline
                  loop={true}
                  muted={true}
                  style={{ width: "100%" }}
                >
                  <source src={personalization} />
                  Your browser does not support the video tag.
                </video>
                <div
                  className="overlayText"
                  style={{
                    position: "absolute",
                    top: "10%",
                    right: "20%",
                    color: "white",
                  }}
                >
                  Health
                  <br />
                  Coaches
                </div>
                <div
                  className="overlayText"
                  style={{
                    position: "absolute",
                    bottom: "20%",
                    right: "20%",
                    color: "white",
                  }}
                >
                  Fitness
                  <br />
                  Experts
                </div>
                <div
                  className="overlayText"
                  style={{
                    position: "absolute",
                    bottom: "20%",
                    left: "20%",
                    color: "white",
                  }}
                >
                  Nutritionist
                  <br />
                  Educator
                </div>
              </div>
            </Col>
            <Col xs={0} md={2} lg={3} />
          </Row>
        </div> */}

        {/* <div style={{ height: "400px" }}></div> */}

        <div
          id="TransformationsSection_blue_bk"
          className="healthGoals_blue_bk"
        >
          <Row>
            <Row className="heading3" style={{}}>
              <Col xs={12} className="heading2">
                Transformation
              </Col>
              <div style={{ marginTop: "2vh" }}>
                <div className="transformation">
                  <div className="transformationrow">
                    <div
                      className="transformationcell"
                      onMouseOver={(e) => {
                        this.raise(e, -5);
                      }}
                      onMouseLeave={(e) => {
                        this.raise(e, 5);
                      }}
                    >
                      <div className="transformationname">Diabetes HBA1c</div>
                      <div className="transformationvalue">-2.1%</div>
                    </div>
                    <div
                      className="transformationcell"
                      onMouseOver={(e) => {
                        this.raise(e, -5);
                      }}
                      onMouseLeave={(e) => {
                        this.raise(e, 5);
                      }}
                    >
                      <div className="transformationname">eGFR</div>
                      <div className="transformationvalue">+7</div>
                    </div>
                    <div
                      className="transformationcell"
                      onMouseOver={(e) => {
                        this.raise(e, -5);
                      }}
                      onMouseLeave={(e) => {
                        this.raise(e, 5);
                      }}
                    >
                      <div className="transformationname">Creatinine</div>
                      <div className="transformationvalue">-0.06</div>
                    </div>

                    <div
                      className="transformationcell"
                      onMouseOver={(e) => {
                        this.raise(e, -5);
                      }}
                      onMouseLeave={(e) => {
                        this.raise(e, 5);
                      }}
                    >
                      <div className="transformationname">
                        Systolic Blood Pressure
                      </div>
                      <div className="transformationvalue">-12</div>
                    </div>
                    <div
                      className="transformationcell"
                      onMouseOver={(e) => {
                        this.raise(e, -5);
                      }}
                      onMouseLeave={(e) => {
                        this.raise(e, 5);
                      }}
                    >
                      <div className="transformationname">
                        Diastolic Blood Pressure
                      </div>
                      <div className="transformationvalue">-5</div>
                    </div>
                    <div
                      className="transformationcell"
                      onMouseOver={(e) => {
                        this.raise(e, -5);
                      }}
                      onMouseLeave={(e) => {
                        this.raise(e, 5);
                      }}
                    >
                      <div className="transformationname">
                        Patients with medicine reduced
                      </div>
                      <div className="transformationvalue">99%</div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Row>
        </div>
        <div className="bannerContainer2">
          <p className="feedbackHeading2">Kind words by Zivovians</p>

          <div className="feedBackRow">
            {UserFeedbackJSON.slice(0, 4).map((feedbackItem, indexfeedback) => (
              <UserFeedback
                {...feedbackItem}
                key={`${indexfeedback}_userfeedbackhome`}
              />
            ))}
          </div>
          <div className="viewall">
            <Link to="/testimonials" className="viewButtonSection">
              <div className="viewAllButton"> View All</div>
            </Link>
          </div>
          <br />
        </div>

        <div id="contactSection" className="bannerContainer">
          <Row>
            <Col xs={12} md={3}>
              <p className="sideheading">Download Zivov App</p>
              <div style={{ marginTop: "10px" }}>
                <a
                  href={process.env.REACT_APP_PLAYSTORE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ProgressiveImage src={images.PLAYSTORE}>
                    {(src) => (
                      <img
                        src={src}
                        alt="Zivov app play store"
                        className="appstoreIcon"
                      />
                    )}
                  </ProgressiveImage>
                </a>
              </div>
              <div style={{ marginTop: "10px" }}>
                <a
                  href={process.env.REACT_APP_STORE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ProgressiveImage src={images.APPSTORE}>
                    {(src) => (
                      <img
                        src={src}
                        alt="Zivov app App store"
                        className="appstoreIcon"
                      />
                    )}
                  </ProgressiveImage>
                </a>
              </div>
            </Col>

            <Col xs={12} md={3}>
              <p className="sideheading">Quick Links</p>
              <Col md={12}>
                <ul>
                  <li>
                    {" "}
                    <Link
                      to="/home"
                      className="quickLink"
                      onClick={() => {
                        this.mainTO();
                      }}
                    >
                      Support
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={12} />

              <div style={{ margin: "2rem" }}>
                <a
                  href="https://www.linkedin.com/company/zivov"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin
                    color="white"
                    size="30"
                    style={{
                      backgroundColor: "#101F40",
                      borderRadius: "10%",
                      marginRight: "1rem",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/zivovhealth/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram
                    color="#101F40"
                    size="30"
                    style={{
                      backgroundColor: "white",
                      marginRight: "1rem",
                      borderRadius: "25%",
                      border: "1px block white",
                    }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/zivovhealth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook
                    color="white"
                    size="30"
                    style={{
                      backgroundColor: "#101F40",
                      borderRadius: "15%",
                      marginRight: "1rem",
                    }}
                  />
                </a>
                <EnvelopeFill
                  color="white"
                  size="30"
                  style={{
                    backgroundColor: "#101F40",
                    borderRadius: "15%",
                    marginRight: "1rem",
                  }}
                  onClick={() => {
                    this.mainTO();
                  }}
                />
              </div>
            </Col>

            {<Col xs={0} md={2} />}

            <Col xs={12} md={3}>
              <div className="sideheading">Get In Touch</div>
              <div
                className="form"
                style={{ color: "white", overflow: "hidden" }}
              >
                <Form
                  onSubmit={this.registerUsertype}
                  noValidate
                  validated={this.state.validated}
                >
                  <Form.Group controlId="formFName">
                    <Form.Label>
                      First Name <span>*</span>
                    </Form.Label>
                    <Form.Control
                      maxLength="15"
                      type="text"
                      onChange={(e) => {
                        this.setStatesValue("fName", e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formLName">
                    <Form.Label>
                      Last Name <span>*</span>
                    </Form.Label>
                    <Form.Control
                      maxLength="15"
                      type="text"
                      onChange={(e) => {
                        this.setStatesValue("lName", e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formPhone">
                    <Form.Label>
                      Phone Number <span>*</span>
                    </Form.Label>
                    <Form.Control
                      value={this.state.phoneNumber}
                      maxLength="10"
                      onInput={this.maxLengthCheck}
                      type="text"
                      onChange={(e) => {
                        this.enterPhoneNumber(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>
                      Email <span>*</span>
                    </Form.Label>
                    <Form.Control
                      maxLength="40"
                      onInput={this.maxLengthCheck}
                      type="email"
                      onChange={(e) => {
                        this.setStatesValue("email", e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <>
                    <Form.Group controlId="formDisease">
                      <Form.Label>
                        Select Condition <span>*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.disease}
                        onChange={(e) => {
                          this.setStatesValue("disease", e.target.value);
                        }}
                        required
                      >
                        <option value=""></option>
                        <option value="Diabetes">Diabetes</option>
                        <option value="Hypertension">Hypertension</option>
                        <option value="Cholesterol">High Cholesterol</option>
                        <option value="Heart">Heart(CVD)</option>
                        <option value="Kidney">Kidney(CKD)</option>
                        <option value="PCOS">PCOS</option>
                        <option value="Prediabetes">Prediabetes</option>
                        <option value="Obesity">Obesity</option>
                        <option value="Cancer">Cancer</option>
                        <option value="COPD">COPD</option>
                        <option value="Prevention">Prevention</option>
                      </Form.Control>
                    </Form.Group>
                  </>

                  <ReCAPTCHA
                    sitekey={"6LfHCdYZAAAAAKRzTdA5iNIk05Z-6pK9Y_4pBQIP"}
                    onChange={(val) => {
                      this.isCaptchaValidated = true;
                    }}
                    style={{ margin: "15px" }}
                  />
                  <Button
                    variant="secondary"
                    className="submitButton"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <p style={{ color: "white" }}>{this.state.errorMsg.msg}</p>
                  {this.state.successDialog && (
                    <p style={{ color: "white" }}>
                      Thank you for contacting Zivov!<br></br>
                      We'll contact you soon.
                    </p>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(ZivovCare);
